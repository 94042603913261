var rxValidEmailChars = /[A-Z0-9._%+\-!#$&'*/=?^`{}|~@]/i;
var rxValidEmail = /^[A-Z0-9._%+\-!#$&'*/=?^`{}|~]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
function validateEmail(val) {
    if (val.length && val.trim != null)
        return rxValidEmail.test(val.trim());
    else
        return false;
}

var rxLegalPhoneChars = /[+0-9]/;
var rxValidPhone = /^\+?[0-9]+$/;
function validatePhone(val) {
    if (val.length && val.length >= 10 && val.trim != null) // Length >= 10 is an NL and client specific request.
        return rxValidPhone.test(val.trim());
    else
        return false;
}

var rxValidPostCodeChars = /[0-9a-z ]/i;
var rxValidPostCode = /^\d{4} ?[a-z]{2}$/i;
function validatePostCode(val) {
    if (val.length && val.trim != null)
        return rxValidPostCode.test(val.trim());
    else
        return false;
}

function easeIn (val) {
  return val * val * val;
}

function easeOut (val) {
  return Math.cbrt(val);
}

function Person() {
    return {
        firstName: new String(),
        middleName: new String(),
        lastName: new String(),
        birthDate: new Date(NaN),
        gender: new String(),
        married: new Boolean(),
        children: new Boolean(),
        streetName: new String(),
        houseNumber: new String(),
        affix: new String(),
        postCode: new String(),
        city: new String(),
        monthlyExpenses: new Number(),
        income: new Number(),
        incomeSource: new Number(NaN),
        livingState: new Number(),
        dateService: new Date(NaN),
        emailAddress: new String(),
        fixedPhoneNumber: new String(),
        mobilePhoneNumber: new String(),
        comments: new String(),
        partnerFirstName: new String(),
        partnerMiddleName: new String(),
        partnerLastName: new String(),
        partnerGender: new String(),
        partnerBirthDate: new Date(NaN),
        partnerIncome: new Number(),
        partnerIncomeSource: new Number(NaN),
        partnerDateService: new Date(NaN)
    };
}

function LoanRequest() {
    return {
        loanType: new Number(),
        months: new Number(),
        loanTypeTable: new Number(),
        loanOption: new Number(NaN),
        loanAmount: new Number(),
        calcResult: new Number(),
        requestedLoanAmount: new Number(NaN),
        maxLoanAmount: new Number(NaN),
        approvedLoanAmount: new Number(NaN),
        prevLoans: new Array(),
        currentLoans: new Object(),
        newLoan: new Object(),
        selectedFunnel: new Number()
    };
}

function CurrentLoans() {
    return {
        total: new Number(),
        averageInterest: new Number(),
        interestYear: new Number()
    };
}

function NewLoan() {
    return {
        total: new Number(),
        effectiveAnnualInterest: new Number(),
        interestYear: new Number(),
        interestSaving: new Number()
    };
}

function PrevLoan() {
    return {
        name: new String(),
        amount: new Number(),
        rate: new Number(),
        ransom: new Boolean()
    };
}

function SimpleContact() {
    return {
        name: new String(),
        phoneNumber: new String(),
        emailAddress: new String()
    }
}

function uiInterface(options) {
    var self = this;
    self.init = false;
    self.empty = true;
    self.disabled = false;
    self.valid = false;
    self.required = false;
    self.visible = false;
    self.isValid = function (oVal, tVal) { // oVal: original set val, tVal: type val from set.
        self.valid = true;

        if (self.init == false && self.required == true) {
            self.valid = false;
        }
        else {
            if (self.validator != null && oVal != "") {
                self.valid = self.validator(oVal);
            }
        }
    };

    if (options)
        Object.assign(self, options);

    if(self.required instanceof Function)
        Object.defineProperty(self, 'required', {
            configurable: true,
            enumerable: true,
            get: self.required
        });

    return self;
}

function uiInterfaceDate(options) {
    var self = new uiInterface(options);

    self.minDay = 1;
    self.maxDay = 31;
    self.minMonth = 1;
    self.maxMonth = 12;
    self.minYear = null;
    self.maxYear = null;
    self.placeholder = "ddmmjj";

    return self;
}

function createProperty(source, target, prop, ui) {
    source[prop].ui = ui;

    if (source[prop].ui.required == false)
        source[prop].ui.valid = true;

    Object.defineProperty(target, prop, {
        configurable: true,
        enumerable: true,
        get() {
            var res;
            res = source[prop];
            if (!res.ui)
                res.ui = ui;
            return res;
        },
        set(val) {
            var ui;
            if (source[prop].ui)
                ui = source[prop].ui;
            if (ui && val != null)
                ui.init = true;
            if (source[prop] instanceof Number) {
                source[prop] = new Number(val);
                if (ui && ui.init && isNaN(val))
                    ui.init = false;
            }
            else if (source[prop] instanceof String) {
                source[prop] = new String(val);
                if (ui && ui.init && val == "")
                    ui.init = false;
            }
            else if (source[prop] instanceof Boolean)
                source[prop] = new Boolean(val.valueOf ? val.valueOf() : val);
            else if (source[prop] instanceof Date) {
                source[prop] = new Date(val);
                if (ui && ui.init && isNaN(val.valueOf()))
                    ui.init = false;
            }
            else
                source[prop] = val;
            if (ui) {
                source[prop].ui = ui;
                ui.isValid(val, source[prop]);
            }
        }
    });
}

var _person = function () {
    var self = this;
    var source = Person();

    function requiredIfMarried() {
        return source.married == true ? true : false;
    }

    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'firstName' }));
    createProperty(source, self, 'middleName', new uiInterface({ required: false, label: 'middleName' }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'lastName' }));
    createProperty(source, self, 'birthDate', new uiInterfaceDate({ required: true, label: 'birthDate' }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: 'gender', optionA: 'male', optionB: 'female', label: 'gender',
        toXML: function (val) {
            return val == "male" ? "M" : "F";
        }
    }));
    createProperty(source, self, 'married', new uiInterface({ required: true, label: 'married' }));
    createProperty(source, self, 'children', new uiInterface({ required: true, label: 'children' }));
    createProperty(source, self, 'streetName', new uiInterface({ required: true, label: 'streetName' }));
    createProperty(source, self, 'houseNumber', new uiInterface({ required: true, label: 'houseNumber', maxVal: 99999 }));
    createProperty(source, self, 'affix', new uiInterface({ required: false, label: 'affix' }));
    createProperty(source, self, 'postCode', new uiInterface({ required: true, label: 'postCode', validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7 }));
    createProperty(source, self, 'city', new uiInterface({ required: true, label: 'city' }));
    createProperty(source, self, 'monthlyExpenses', new uiInterface({ required: true, minLen: 1, maxLen: 5, minVal: 1, maxVal: 2500, step: 1, unit: "€", placeholder: "€200", label: 'monthlyExpenses', eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'income', new uiInterface({ required: true, minLen: 1, maxLen: 5, minVal: 1, maxVal: 5000, step: 1, unit: "€", placeholder: "€500", label: 'income', eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'incomeSource', new uiInterface({ required: true, placeholder: 'Soort dienstverband', label: 'incomeSource' }));
    createProperty(source, self, 'livingState', new uiInterface({ required: true, placeholder: "Woonsituatie", label: 'livingState' }));
    createProperty(source, self, 'dateService', new uiInterfaceDate({ required: true, label: 'dateService' }));
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, validator: validateEmail, label: 'emailAddress', rxValidChars: rxValidEmailChars }));
    createProperty(source, self, 'fixedPhoneNumber', new uiInterface({ validator: validatePhone, minLen: 10, maxLen: 10, label: 'fixedPhoneNumber' }));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, validator: validatePhone, minLen: 10, maxLen: 10, label: 'mobilePhoneNumber' }));
    createProperty(source, self, 'comments', new uiInterface({ required: true, label: 'comments' }));

    ////////////////////////////////////////////////////////////////////
    createProperty(source, self, 'partnerFirstName', new uiInterface({ required: requiredIfMarried, label: 'partnerFirstName' }));
    createProperty(source, self, 'partnerMiddleName', new uiInterface({ required: false, label: 'partnerMiddleName' }));
    createProperty(source, self, 'partnerLastName', new uiInterface({ required: requiredIfMarried, label: 'partnerLastName' }));
    createProperty(source, self, 'partnerGender', new uiInterface({ required: requiredIfMarried, name: 'partnerGender', optionA: 'male', optionB: 'female', label: 'partnerGender',
        toXML: function (val) {
            return val == "male" ? "M" : "F";
        }
    }));
    createProperty(source, self, 'partnerBirthDate', new uiInterfaceDate({ required: requiredIfMarried, label: 'partnerBirthDate' }));
    createProperty(source, self, 'partnerIncome', new uiInterface({ required: requiredIfMarried, minLen: 1, maxLen: 5, minVal: 1, maxVal: 5000, step: 1, unit: "€", placeholder: "€500", label: 'partnerIncome', eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'partnerIncomeSource', new uiInterface({ required: requiredIfMarried, placeholder: 'Soort dienstverband', label: 'partnerIncomeSource' }));
    createProperty(source, self, 'partnerDateService', new uiInterfaceDate({ required: requiredIfMarried, label: 'partnerDateService' }));
    return self;
};

var _loanRequest = function () {
    var self = this;
    var source = LoanRequest();

    createProperty(source, self, 'loanType', new uiInterface({ required: true, placeholder: 'Maak uw keuze', label: 'loanType' }));
    createProperty(source, self, 'months', new uiInterface({ required: true, placeholder: 'Maak uw keuze', label: 'months' }));
    createProperty(source, self, 'loanTypeTable', new uiInterface({ required: true, label: 'loanTypeTable' }));
    createProperty(source, self, 'loanOption', new uiInterface({
        required: true, placeholder: 'Maak uw keuze', label: 'loanOption',
        toXML: function (val) {
            var res = null;
            switch (val.valueOf()) { // Needed valueOf as val is Number().
                case 0:
                    res = 'AA';
                    break;
                case 1:
                    res = 'BB';
                    break;
                case 2:
                    res = 'CC';
                    break;
                case 3:
                    res = 'DD';
                    break;
            }
            return res;
        }
    }));
    createProperty(source, self, 'loanAmount', new uiInterface({ required: true, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 100, unit: "€", placeholder: "€2.500", label: 'loanAmount', eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'requestedLoanAmount', new uiInterface({ required: true, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 10, unit: "€", placeholder: "€2.500", label: 'requestedLoanAmount' }));
    createProperty(source, self, 'maxLoanAmount', new uiInterface({ label: 'maxLoanAmount' }));
    createProperty(source, self, 'approvedLoanAmount', new uiInterface({ required: true, minLen: 4, maxLen: 6, minVal: 2500, maxVal: 100000, step: 100, unit: "€", placeholder: "€2.500", label: 'approvedLoanAmount', eIn: easeIn, eOut: easeOut }));
    createProperty(source, self, 'prevLoans', new uiInterface({ required: true, label: 'prevLoans' }));
    createProperty(source, self, 'selectedFunnel', new uiInterface({ required: true, label: 'selectedFunnel' }));
    return self;
};

var _currentLoans = function () {
    var self = this;
    var source = CurrentLoans();
    createProperty(source, self, 'total', new uiInterface({ disabled: true, unit: '€', label: 'total' }));
    createProperty(source, self, 'averageInterest', new uiInterface({ disabled: true, unit: '%', label: 'averageInterest' }));
    createProperty(source, self, 'interestYear', new uiInterface({ disabled: true, unit: '€', label: 'interestYear' }));

    return self;
};

var _newLoan = function () {
    var self = this;
    var source = NewLoan();

    createProperty(source, self, 'total', new uiInterface({ disabled: true, unit: '€', label: 'total' }));
    createProperty(source, self, 'effectiveAnnualInterest', new uiInterface({ disabled: true, unit: '%', label: 'effectiveAnnualInterest' }));
    createProperty(source, self, 'interestYear', new uiInterface({ disabled: true, unit: '€', label: 'interestYear' }));
    createProperty(source, self, 'interestSaving', new uiInterface({ disabled: true, unit: '€', label: 'interestSaving' }));

    return self;
};

var _prevLoan = function () {
    var self = this;
    var source = PrevLoan();
    createProperty(source, self, 'name', new uiInterface({ required: false, label: 'name' }));
    createProperty(source, self, 'amount', new uiInterface({ required: true, minLen: 3, maxLen: 5, minVal: 0, maxVal: 200000, step: 10, unit: "€", placeholder: "€10.000", label: 'amount' }));
    createProperty(source, self, 'rate', new uiInterface({ required: true, minLen: 1, maxLen: 6, minVal: 0, maxVal: 100, step: 0.01, unit: "%", placeholder: "7,5%", label: 'rate' }));
    createProperty(source, self, 'ransom', new uiInterface({ required: true, label: 'ransom' }));
    return self;
};

export { _person, _loanRequest, _prevLoan, _currentLoans, _newLoan, uiInterface, uiInterfaceDate, createProperty, validateEmail, validatePhone, rxValidEmailChars, rxLegalPhoneChars,  validatePostCode, rxValidPostCodeChars};
