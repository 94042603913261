import { render, staticRenderFns } from "./expitems.vue?vue&type=template&id=87b447aa&scoped=true&"
import script from "./expitems.vue?vue&type=script&lang=js&"
export * from "./expitems.vue?vue&type=script&lang=js&"
import style0 from "./expitems.vue?vue&type=style&index=0&id=87b447aa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b447aa",
  null
  
)

export default component.exports